import {
    Abstract
} from './Abstract';
import axios from "axios";

export class BWInvoiceItem extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/Invoice', session);
        this.id_cybersource_invoice_items = null;
        this.number = null;
        this.productSku = null;
        this.productName = null;
        this.quantity = 1;
        this.unitPrice = 0.00;
        this.iva = 0.00;
    }

    async allitem() {
     
        let response = await axios.get(this.controller + '/allitem', {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
}