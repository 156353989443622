import {
    Abstract
} from './Abstract';

import axios from 'axios';

export class BWInvoice extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/Invoice', session);
        this.customerInformation_name = null;
        this.customerInformation_email = null;
        this.customerInformation_merchantCustomerId = null;
        this.invoiceInformation_description = null;
        this.invoiceInformation_dueDate = null;
        this.invoiceInformation_sendImmediatly = true;
        this.invoiceInformation_allowPartialPayments = false;
        this.invoiceInformation_deiveryMode = 'email';
        this.orderInformation_amountDetails_totalAmount = 0.00;
        this.orderInformation_amountDetails_currency = 'MXN';
        this.orderInformation_amountDetails_discountAmount = 0.00;
        this.orderInformation_amountDetails_discountPercent = 0.00;
        this.orderInformation_amountDetails_subAmount = 0.00;
        this.orderInformation_amountDetails_minimumPartialAmount = 0.00;
        this.orderInformation_taxDetails_type = "vat";
        this.orderInformation_taxDetails_amount = 0.00;
        this.orderInformation_taxDetails_rate = null;
        this.orderInformation_freight_amount = 0.00;
        this.orderInformation_freight_taxable = false;
        this.items = [];
    }

    async readPayments() {
        let response = await axios.post(this.controller + 'read/payments', this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async send() {
        let response = await axios.post(this.controller + '/send/' + this.id, this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async InvoicePay(id,company,branch) {

        if (id == 0 || id == "" || id == null)
        throw "ERROR. Ingrese un id para encontrar el registro"

    let response = await axios.get(this.controller + '/invoicePay/'  + id + "/" +company +"/" + branch, {
        headers: {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch
        }
    });
    return response.data;
    }

    async getTransactions(initial_date, final_date, branch, status) {
        branch = branch ?? this.id_branch;
        let response = await axios.post(this.controller +'/gettransactions', {
          initial_date: initial_date,
          final_date: final_date,
          status: status
        }, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: branch 
            }
        });
        return response.data;
      }

 

    async retrieveItem() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Ingrese un id para encontrar el registro"
        let response = await axios.get(this.controller + '/item/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }


    async allitem() {
     
        let response = await axios.get(this.controller + '/allitem', {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async allCustomer() {
     
        let response = await axios.get(this.controller + '/allCustomer', {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
}