<template>
    <div class="grid crud-demo">
    <Loader v-model="loading" />
    <Helper v-model="productHelper" header="Buscar Producto" :headers="productHeaders" :rows="products" @selected="selectProduct" />
    <Helper v-model="invoiceHelper" header="Buscar Link de Pago" :headers="invoiceHeaders" :rows="entities" @selected="selectEntity" />
    <Toast />
    <div class="col-12">
      <div class="card">
        <Panel header="Venta con Link de Pago">
          <BasicFormToolbar @new="newInvoice" @cancel="cancel" @save="save" @search="invoiceHelper.visible = true" 
          @send="sendInvoice"
          :actions="['save','new','search','refresh','send', 'cancel']"/>
         <TabView>
	<TabPanel header="Informacion General">
       
		 <div class="grid">
            <div class="col-12 xl:col-6">
                <Fieldset :toggleable="true" legend="Informacion del Cliente">
                    <div class="p-fluid formgrid grid">
                        <FormInputText v-model="entity.customerInformation_name" label="Nombre" wrapperClass="field col-12 xl:col-6"  
                        :valid="validate.validations.customerInformation_name" />
                        <FormInputText v-model="entity.customerInformation_email" label="E-mail" wrapperClass="field col-12 xl:col-6"  
                        :valid="validate.validations.customerInformation_email" :validation="'Favor de validar el campo con un correo valido ***@hostname.com'" />
                    </div>
                </Fieldset> <br>
                <Fieldset :toggleable="true" legend="Informacion de Orden">
                <div class="p-fluid formgrid grid">
                        <!-- Queda pendiente -->
                        <div class="field col-12 xl:col-4">
                            <label>Moneda</label>
                            <Dropdown 
                            v-model="entity.orderInformation_amountDetails_currency"
                            :filter="true" 
                            :options="c_Moneda" 
                            optionLabel="c_Moneda" 
                            optionValue="c_Moneda">
                                <template #option="slotProps">
                                    <div>
                                        <div>{{ slotProps.option.c_Moneda }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                    </div>
                                </template>
                            </Dropdown>
                            <small class="p-invalid" v-if="validate.validations.orderInformation_amountDetails_currency === false"> 
                            Favor de llenar el campo 
                            </small>
                        </div>
                        <FormInputNumber :disabled="true" v-model="subtotal" label="Total" wrapperClass="field col-12 xl:col-4" mode="currency" />
                        <FormInputNumber :disabled="true" v-model="impuestos" label="Impuestos" wrapperClass="field col-12 xl:col-4" mode="currency" />
                        <FormInputNumber v-model="entity.orderInformation_amountDetails_discountPercent" label="% Descuento" wrapperClass="field col-12 xl:col-6" mode="decimal" prefix="%" :min="0" :maxFractionDigits="2" :max="100" :minFractionDigits="2" />
                        <FormInputNumber :disabled="true" v-model="entity.orderInformation_amountDetails_discountAmount" label="Importe Descuento" wrapperClass="field col-12 xl:col-6" mode="currency" />
                        <FormInputNumber :disabled="true" v-model="entity.orderInformation_freight_amount" label="Flete" wrapperClass="field col-12 xl:col-6" mode="currency" />
                        <FormInputNumber v-model="entity.orderInformation_amountDetails_minimumPartialAmount" label="Minimo de Pago" wrapperClass="field col-12 xl:col-6" mode="currency" />
                </div>
                </Fieldset>
            </div>
            <div class="col-12 xl:col-6">
                <Fieldset :toggleable="true" legend="Informacion de Venta">
                    <div class="p-fluid formgrid grid">
                    <FormInputText label="Folio" :valid="validate.validations.invoiceInformation_number" v-model="entity.invoiceInformation_number" wrapperClass="field col-4" />
                    <!-- <FormCalendar label="Fecha Vencimiento" v-model="entity.invoiceInformation_dueDate" wrapperClass="field col-8" /> -->
                    <div class="field col-8">
                        <label for="sat">Fecha Vencimiento</label>
                        <Calendar dateFormat="dd/mm/yy" v-model="entity.invoiceInformation_dueDate" :showIcon="true" />
                    </div>
                    
                    <FormInputText label="Descripcion" v-model="entity.invoiceInformation_description" wrapperClass="field col-12"  
                    :textArea="true" :valid="validate.validations.invoiceInformation_description" />
                    <FormDropdown label="Enviar Inmediatamente" wrapperClass="field col-4" v-model="entity.invoiceInformation_sendImmediatly" 
                    :options="[{value:true, text: 'SI'},{value:false, text: 'NO'}]" optionLabel="text" optionValue="value" />
                    <FormDropdown label="Pagos parciales" wrapperClass="field col-4" v-model="entity.invoiceInformation_allowPartialPayments" 
                    :options="[{value:true, text: 'SI'},{value:false, text: 'NO'}]" optionLabel="text" optionValue="value" />
                    <FormDropdown :disabled="entity.invoiceInformation_sendImmediatly" label="Modo de Envio" wrapperClass="field col-4" v-model="entity.invoiceInformation_deiveryMode" 
                    :options="deliveryMethods" optionLabel="text" optionValue="value" />
                    <FormInputNumber v-model="entity.orderInformation_taxDetails_rate" label="IVA %" wrapperClass="field col-2" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"/>
</div>
                </Fieldset> <br>
                <Fieldset :toggleable="true" legend="Link">
                    <div class="p-inputgroup">
                        <InputText :readonly="true" ref="paymentLink" v-model="entity.paymentLink" />
                        <Button @click="copyLink" icon="pi pi-copy" class="p-button-primary" />
                        <Button @click="sendWhatsapp" icon="pi pi-whatsapp" class="p-button-success" />
                    </div>
                </Fieldset>
            </div>
          </div>
          <br>
           <Fieldset :toggleable="true" legend="Items">
            <BasicFormToolbar :actions="['save','new']" @new="newItem" @save="saveItem"/>
            <div class="p-fluid formgrid grid">
                <FormInputText :search="true" ref="sku"  v-model="invoiceItem.productSku" label="SKU" wrapperClass="field col-12 xl:col-2"  
                :valid="itemValidation.validations.productSku" @search="productHelper.visible = true" @tab="searchProduct" />
                <FormInputText v-model="invoiceItem.productName" label="Descripcion" wrapperClass="field col-12 xl:col-6"  
                :valid="itemValidation.validations.productName"  />
                <FormInputNumber v-model="invoiceItem.quantity" label="Cantidad" wrapperClass="field col-12 xl:col-2"  
                :valid="itemValidation.validations.quantity" validation="Fabor de llenar el campo, la cantidad debe ser mayor a 0" />
                <FormInputNumber v-model="invoiceItem.unitPrice" label="Precio" wrapperClass="field col-12 xl:col-2"  
                :valid="itemValidation.validations.unitPrice" mode="currency" @tab="saveItem($event)" @enter="saveItem($event)" validation="Favor de llenar el campo, el precio debe ser mayor a $0.00"/>
                <div class="col-12">
                    <BasicDatatable 
                    @selected="selectItem" 
                    :headers="itemHeaders"
                    :rowaction="true"
                    :rowdelete="true"
                    :selectionMode="'single'" 
                    :dataKey="'number'"
                    @deleted="deleteItem"
                    :rows="entity.items" />
                </div>
            </div> 
          </Fieldset>
	</TabPanel>
	<TabPanel header="Informacion de Pago">
	     <div class="p-fluid p-formgrid grid">
            <div class="field col-12">
                <Fieldset :toggleable="true" legend="Resultados">
                    <BasicDatatable 
                    :menuModel="[
                        {label: 'Copiar Link', icon: 'pi pi-fw pi-copy', action: 'copy'},
                        {label: 'Mandar Link por WhatsApp', icon: 'pi pi-fw pi-whatsapp', action: 'whatsapp'},
                        {label: 'Enviar por e-mail', icon: 'pi pi-fw pi-envelope', action: 'send'},
                    ]"
                    @copy="copy" @whatsapp="whatsapp" @send="send" @selected="selectItem"
                    :contextMenu="true" :rowaction="true"  :headers="headers" :rows="invoicePays" />
                </Fieldset>
            </div>
        </div>
<div class="p-fluid formgrid grid">
    <FormInputNumber :disabled="true" v-model="SUMATO" label="Total de Venta" wrapperClass="field col-12 xl:col-4"
        mode="currency" />
    <FormInputNumber :disabled="true" :mode="'currency'" v-model="total" class="field col-4" label="Total Pagado" />
    <FormInputNumber :disabled="true" v-model="TOTALAP" label="Total a Pagar" wrapperClass="field col-12 xl:col-4"
        mode="currency" />
</div>
</TabPanel>
	
</TabView>
         
         
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
import Loader from '../../../components/general/Loader.vue'
import BasicDatatable from '../../../components/general/BasicDatatable.vue'
import FormInputText from '../../../components/general/FormInputText.vue'
import FormInputNumber from '../../../components/general/FormInputNumber.vue'
import FormDropdown from '../../../components/general/FormDropdown.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue'
import Helper from '../../../components/general/HelperDialog.vue'
import sessionMixin from '../../../mixins/sessionMixin'
import { BWInvoice } from '../../../models/custom/business_wallet/BWInvoice'
import { BWInvoiceItem } from '../../../models/custom/business_wallet/BWInvoiceItem'
import { ErrorToast, fillObject, HeaderGrid, Rule, satCatalogo, Toast, validate } from '../../../utilities/General'
import { BWProduct } from '../../../models/custom/business_wallet/BWProduct'
var math = require("mathjs");
export default {
    mixins: [sessionMixin],
     props: {
        id: null,
    },
    data() {
        return {
            entity: new BWInvoice(this.session),
            invoiceItem:  new BWInvoiceItem(this.session),
            loading: false,
            invoiceHelper:  {
                visible: false,
            },
            productHelper:  {
                visible: false,
            },
            products: [],
            invoiceHeaders: [
                new HeaderGrid('Folio', 'invoiceInformation_number'),
                new HeaderGrid('Descripcion', 'invoiceInformation_description'),
                new HeaderGrid('Fecha Vence', 'invoiceInformation_dueDate',  {type: 'date'}),
                new HeaderGrid('Moneda', 'orderInformation_amountDetails_currency'),
                new HeaderGrid('Total', 'orderInformation_amountDetails_totalAmount', {type: 'currency'})
           
           ],
             productHeaders: [
                new HeaderGrid("SKU", "sku"),
                new HeaderGrid("Nombre", "name"),
                new HeaderGrid("P.U.", "unit_price", {type: "currency"}),

            ],
               headers: [ 
                new HeaderGrid('ID','id'),
                new HeaderGrid('Cliente','customerInformation_name'),
                new HeaderGrid('Descripcion','invoiceInformation_description'),
                new HeaderGrid('Total a Pagar','orderInformation_amountDetails_totalAmount', {type: 'currency'}),
                new HeaderGrid('Fecha de Importe Efectuado','created', { type: 'date'}),
                new HeaderGrid('Dias Pendientes', 'dias_pendientes', { formula: 'today_difference', expression: 'invoiceInformation_dueDate'}),
                new HeaderGrid('Saldo Pagado', 'auth_amount',{type: 'currency', function: 'SUM'}),
                // new HeaderGrid('Saldo Pendiente', 'sal_pendiente',{type: 'currency', function: 'SUM'}),
                //  new HeaderGrid('Estatus', 'status')
            ],
         
            deliveryMethods: [
                { value: "none", text: "Ninguno"},
                { value: "email", text: "Email"}
            ],
            itemValidation: {
                valid: false,
                validations: {
                    productSku: null,
                    productName: null,
                    quantity: null,
                    unitPrice: null
                },
            },
            
            itemRules: [
                new Rule({ name: 'productSku' }),
                new Rule({ name: 'productName' }),
                new Rule({ name: 'quantity', value: 'number', type: 'min', min: 0 }),
                new Rule({ name: 'unitPrice', value: 'number', type: 'min', min: 0 })
            ],
            rules: [
                new Rule({ name: 'customerInformation_name' }),
                new Rule({ name: 'customerInformation_email', type: 'email' }),
                new Rule({ name: 'invoiceInformation_number' }),
                new Rule({ name: 'invoiceInformation_description' })
            ],
            validate: {
                valid: false,
                validations: {
                    customerInformation_name: null,
                    customerInformation_email: null,
                    invoiceInformation_number: null,
                    invoiceInformation_description: null
                },
            },
            itemHeaders: [
                new HeaderGrid('#', 'number'),
                new HeaderGrid('SKU', 'productSku'),
                new HeaderGrid('Nombre', 'productName'),
                new HeaderGrid('Cantidad', 'quantity', {editable: true}),
                new HeaderGrid('Precio', 'unitPrice', { type: 'currency', editable: true}),
                new HeaderGrid('Total', 'total', { type: 'currency', formula: 'evaluate', function: 'SUM', expression: 'unitPrice * quantity'}),
            ],
            c_Moneda: [],
            entities: [],
            invoicePays: []
        }
    },
    watch: {
        "entity.invoiceInformation_sendImmediatly"(newValue) {
            if (newValue) {
                this.entity.invoiceInformation_deiveryMode = "email";
            }
        }
    },
    computed: {
        SUMATO (){
            return this.subtotal +this.impuestos- this.entity.orderInformation_amountDetails_discountAmount+this.entity.orderInformation_freight_amount;
        },
        total() {
            return this.invoicePays.reduce((a, b) => {
           return  (math.chain(a).add(b.auth_amount ?? 0.00).done());
            }, 0);
        },
        TOTALAP(){
            return this.SUMATO - this.total;
        },
        subtotal() {
            let subtotal = this.entity.items.reduce((a, b) => {
                var itemSubtotal = math.chain(b.quantity).multiply(b.unitPrice).done();
                return math.chain(a).add(itemSubtotal).done();
            }, 0);
            return subtotal ? subtotal : 0.0;
        },
        impuestos() {
            if (this.subtotal == 0 
            || this.entity.orderInformation_taxDetails_rate == null 
            || this.entity.orderInformation_taxDetails_rate < 0
            || this.entity.orderInformation_taxDetails_rate == 0) {
                return 0;
            }else {
                let percentage = math.chain(this.entity.orderInformation_taxDetails_rate).divide(100).done();
                return math.chain(this.subtotal).multiply(percentage).done();
            }
        }
    },
    methods: {
        selectItem(item) {
            this.invoiceItem = fillObject(this.invoiceItem, item);
        },
        copyLink() {
            if (this.entity.paymentLink) {
                navigator.clipboard.writeText(this.entity.paymentLink);
                this.$toast.add(
                    new Toast({
                        summary: "Link de Pago",
                        detail: "Se ha copiado el link en el portapapeles",
                        })
                );
            }
        },
        sendWhatsapp() {
            if (this.entity.paymentLink) {
                window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(this.entity.paymentLink));
            }
        },
        searchProduct() {
            let product = this.products.find(x => x.sku == this.invoiceItem.productSku);
            console.log(product);
            if (product) 
                this.selectProduct(product);
        },
        newInvoice() {
            this.entity = new BWInvoice(this.session);
            this.invoiceItem = new BWInvoiceItem(this.session); 
        },
        async selectEntity(invoice) {
            this.entity = fillObject(this.entity, invoice);
            this.entity.invoiceInformation_dueDate = new Date(this.entity.invoiceInformation_dueDate);
            this.invoicePays = await new BWInvoice().InvoicePay(
                     this.entity.invoiceInformation_number,
                     this.entity.id_company,
                     this.entity.id_branch
                );
       },
        async refresh() {
            this.loading = true;
            try {
                this.entity = new BWInvoice(this.session);
                this.invoiceItem = new BWInvoiceItem(this.session); 
                this.products = await new BWProduct(this.session).all();
                this.entities = await this.entity.all();
              
                 if (this.id) {
                    this.entity.id = this.id;
                    this.invoiceItem.id = this.id;
                    let retrieved = await this.entity.retrieve();
                    this.entity = fillObject(this.entity, retrieved);
                    this.entity.items = await this.entity.retrieveItem();
                    this.entity.invoiceInformation_dueDate = new Date(this.entity.invoiceInformation_dueDate);
                 this.invoicePays = await new BWInvoice().InvoicePay(
                     this.entity.id,
                     this.entity.id_company,
                     this.entity.id_branch
                 
                 
                );
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async sendInvoice() {
            this.loading = true;
            try {
                //Validamos que guardamos si no tenemos el ID
                if (this.entity.id == null)
                {
                    this.entity.invoiceInformation_sendImmediatly = true;
                    this.entity.invoiceInformation_deiveryMode = 'email';
                    await this.save();
                }
                let response = await this.entity.send();
                this.entity.paymentLink = response.paymentLink;
                this.$refs.paymentLink.$el.focus();
                this.$toast.add(
                    new Toast({
                        summary: "Link de Pago",
                        detail: "Se ha enviado correctamente el correo",
                        })
                );
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        deleteItem(item) {
            try {
              if (item.id)
                throw "No se puede eliminar una partida ya guardada"

              let number = item.number;
              this.entity.items = this.entity.items.filter(x => x.number != item.number);
              this.entity.items.forEach(x => {
                if (x.number > number) {
                    x.number = x.number - 1;
                }
              });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        selectProduct(product) {
            this.invoiceItem.productSku = product.sku;
            this.invoiceItem.productName = product.name;
            this.invoiceItem.unitPrice = product.unit_price;
            this.productHelper.visible = false;
        },
        async save() {
            this.loading = true;
            try {
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) throw 'Favor de validar los campos';
                if (this.entity.id) {
                    let invoice = await this.entity.update();
                    let index = this.entities.findIndex(x => x.id == invoice.id);
                    this.entity.items = invoice.items;
                    this.entities[index] = {
                        ...this.entity
                    }
                }else {
                    //Buscamos si ya existe
               
                    
                    let invoice = await this.entity.save();
                    this.entities.push(invoice);
                    this.entity.id = invoice.id;
                    this.entity.paymentLink = invoice.paymentLink;
                    this.$refs.paymentLink.$el.focus();
                    this.entity.items = invoice.items;
                }
                this.$toast.add(
                new Toast({
                    summary: "Moviemiento de Almacen",
                    detail: "Informacion guardada con exito",
                    })
                );
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        newItem() {
            this.invoiceItem = new BWInvoiceItem(this.session);
            this.itemValidation.valid = true;
            this.itemValidation.validations
            //* Limpiar validaciones 
            Object.keys(this.itemValidation.validations).forEach(prop => this.itemValidation.validations[prop] = null);
        },
        saveItem(event) {
            //* Validamos
            this.itemValidation = validate(this.invoiceItem, this.itemRules);
            if (!this.itemValidation.valid) {
                if (event) {
                    event.preventDefault();
                }
                throw 'Favor de validar los campos';
            }

            if (this.invoiceItem.number) {
                let index = this.entity.items.findIndex(x => x.number == this.invoiceItem.number);
                this.entity.items[index] = {...this.invoiceItem};
            }else {
                this.invoiceItem.number = this.invoiceItem.number ? this.invoiceItem.number : this.entity.items.length + 1;
                this.entity.items.push({
                ...this.invoiceItem
                });
            }

            
            this.invoiceItem = new BWInvoiceItem(this.session);
            this.$refs.sku.focus();
        },
        async cancel() {
            this.loading = true;
            try {
                let response = await this.entity.delete();
                console.log(response);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        }
    },
    async mounted() {
        this.c_Moneda = await satCatalogo(9);
        await this.refresh();
    },
    components: {
        Loader,
        BasicDatatable,
        FormInputText,
        FormInputNumber,
        BasicFormToolbar,
        FormDropdown,
        Helper
    }
}
</script>

<style>

</style>